<template>
    <v-dialog :value="true" overlay-opacity="0.6" overlay-color="#000" persistent max-width="500px">
        <v-card>
            <v-card-title class="py-2 dcard-title">
                <h3 class="headline mb-0 primary--text text-center">Verify Your Number:</h3>
            </v-card-title>
            <v-card-text class="pa-0 pb-2">
                <v-divider />
                <v-container pa-0 pt-4 pb-3 px-3>
                    <v-row v-show="!showResend">
                        <v-col cols="12" class="text-center">
                            <v-alert dense type="error" v-if="errors">
                                {{ errors }}
                            </v-alert>
                            <p class="subtitle-1 lh-14 mb-2 mb-md-0">A verification code has been sent to <br class="hidden-md-and-up" /><b>{{ user.phone }}</b>.</p>
                            <p class="subtitle-1 lh-14 mb-2">Enter the code below to verify we have the correct phone number.</p>
                        </v-col>
                        <v-col cols="12" class="text-center py-0">
                            <CodeInput :size="4" @updated="code=$event" />
                            <v-btn class="mt-3 mb-1" @click="showResend = true" small text color="primary">Didn't Receive a Code?</v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-show="showResend">
                        <v-col cols="12" class="text-center pt-0">
                            <p class="subtitle-1 mb-3">Resend verification code to <b>{{ user.phone }}</b>:</p>
                            <v-btn class="mb-1" :loading="resending" @click="resend()" color="primary">Resend Code</v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="card-actions secondary">
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="loading" :disabled="code.length != 4" dark class="px-3" @click.native="verify()">Verify Your Number</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import { mapState } from 'vuex'

export default {

    name: 'ConfirmPhoneModal',
    props: {
    },
    components: {
        CodeInput: () => import('@/components/common/CodeInput'),
    },
    computed: {
        ...mapState(['user'])        
    },
    data: () => ({
        code: '',
        errors: null,
        loading: false,
        resending: false,
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 3 || 'Must be at least 4 numbers.',
        },
        showResend: false,
    }),
    methods: {
        resend(){
            this.errors = null;
            this.resending = true;
            let request = {
                endpoint: '/sendverificationcode', 
                method: 'get',
                data: {},
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your verification code has been sent!'
                    });
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.resending = false;
                this.showResend = false;
            });
        },
        verify(){
            this.loading = true;
            let request = {
                endpoint: '/verifyphone', 
                method: 'post',
                data: {
                    code: this.code
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('refreshUser');
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your phone has been verified!'
                    });
                    this.close();
                }else{
                    this.errors = "Invalid code provided. Please try again.";
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.loading = false;
            });
        },
        close(){
          this.$emit('closed', true);
        }     
    },
    mounted(){
    },
    watch: {
    }
}
</script>
